import React, {useState, useEffect } from 'react';
import './totp-disable-modal.scss';
import Modal from '../../common/modal/modal';
import PasswordInput from '../../elements/password-input/password-input';
import Button from '../../elements/button/button';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import useUserState from '../../../store/user';
import { clone } from '../../../utils/clone';
import { Link } from 'react-router-dom';


const TotpDisableModal = ({show,handleClose}) => {

  const {user,setUser} = useUserState();

  const [loading,setLoading] = useState(false);
  const [password,setPassword] = useState("");
  const [inputVal,setInputVal] = useState("");

  useEffect(() => {
    return () => {
      unSubRequest("user-totp-disable");
    }
  },[]); 

  const handleSubmit = (e) => {
    e.preventDefault();
    disableTotpRequest();
  }

  const disableTotpRequest = () => {

    let data = {
      password: password,
      totpToken: inputVal,
    }

    setLoading(true);
    request("user-totp-disable","/user-totp-delete","POST", data, {
      then: function(res){
        toast.success("TOTP disabled");
        let cloneUser = clone(user);
        cloneUser.totpSet = false;
        setUser(cloneUser);
        handleClose();
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  return (
    <Modal className="totp-disable-modal" show={show} handleClose={handleClose}>
      <div className="totp-disable-modal-div">
        <h3>Disable TOTP</h3>
        <div className="block">
          <form onSubmit={handleSubmit}>
            <label>Account Password <span className="forgot-password"><Link tabIndex="-1" to="/reset-password">Forgot?</Link></span></label>
            <PasswordInput value={password} placeholder="Password" onChange={(e) => {setPassword(e.target.value)}}/>

            <label>Enter the 6 digit TOTP code here. </label>
            <input type="text" placeholder="Code" value={inputVal} onChange={(e) => {setInputVal(e.target.value)}}/>
            <Link to="/totp-reset">Security verification unavailable?</Link>
            <Button type="submit" status={(loading)?"loading":""} />
          </form>
        </div>
      </div>
    </Modal>
  );

};

export default TotpDisableModal;