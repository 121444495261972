import React,{useEffect,useCallback,} from 'react';
import './confirm-modal.scss'
import CenterDiv from '../center-div/center-div';
import {ReactComponent as CloseSVG} from '../../../css/imgs/icon-close.svg';

const ConfirmModal = ({ className, handleClose, show, children }) => {
  const showHideClassname = show ? " show-confirm-modal" : " hide-confirm-modal";

  const keydownListenerCallback = useCallback(
    (e) => { 
      if(e.keyCode === 27) {// esc 
        // handleClose();
        localHandleClose(handleClose,);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[handleClose,],
  );

  useEffect(() =>{
    document.addEventListener("keydown", keydownListenerCallback, false);
    return () => {
      document.removeEventListener("keydown", keydownListenerCallback, false);
    }
  },[keydownListenerCallback]);

  useEffect(() =>{
    if(show){
      document.body.style.overflow = "hidden";
    }else{
      document.body.style.overflow = "";
    }
  },[show]);

  const localHandleClose = (handleClose,confirmed) => {
    confirmed = confirmed?true:false;
    handleClose(confirmed);
  }

  return(
    <div className={"confirm-modal"+showHideClassname}>
      <div onClick={() =>{localHandleClose(handleClose,)}} className="confirm-modal-gray-screen"></div>
        <CenterDiv>
          <div className={"confirm-modal-main "+className}>
            <div onClick={() =>{localHandleClose(handleClose,)}} className={'confirm-modal-close-button'}>
              <CloseSVG />
            </div>
            <div className='confirm-modal-header'>
              <h3>Confirm</h3>
            </div>
            <div className="confirm-modal-content">
              {children}
            </div>
            <div className="confirm-modal-buttons">
              <button className='button clear' type="button" onClick={() => {
                localHandleClose(handleClose);
              }}>Cancel</button>
              <button className='button' type="button" onClick={() => {
                localHandleClose(handleClose,true);
              }}>Confirm</button>
            </div>
          </div>
        </CenterDiv>
    </div>
  );
};

export default ConfirmModal;