import React,{useState,useEffect,} from 'react';
import { NavLink } from 'react-router-dom';
import './navi-footer.scss';
import HoverDiv from '../elements/hover-div/hover-div';
import { t } from "../../translations/i18n";

import useUserState from '../../store/user';

import {ReactComponent as LanguageSvg} from '../../css/imgs/icon-language.svg';
import {ReactComponent as LoginSvg} from '../../css/imgs/icon-login.svg';
import {ReactComponent as LogoutSvg} from '../../css/imgs/icon-logout.svg';
import {ReactComponent as PersonSvg} from '../../css/imgs/icon-person.svg';
import {ReactComponent as PersonAddSvg} from '../../css/imgs/icon-person-add.svg';
import {ReactComponent as NotificationsSvg} from '../../css/imgs/icon-notifications.svg';
import useModalState,{ModalEnum} from '../../store/modal';

const NaviFooter = ({hoverCallback}) => {

  const {user,logoutUser} = useUserState();
  const {setActiveModal} = useModalState();

  const [showAccountMenu,setShowAccountMenu] = useState(false);

  useEffect(() => {
    const ignoreClickIds = ["account-username","account-menu"];

    const listener = (e) => {
      if(ignoreClickIds.indexOf(e.target.id) === -1){
        setShowAccountMenu(false);
      }
    }
    if(showAccountMenu){
      window.addEventListener("click", listener);
    }
    return () => {
      if(showAccountMenu){
        window.removeEventListener("click", listener);
      }
    }
  },[showAccountMenu]);

  let displayText = t("navi.language");
  return (
    <div className={"navi-footer"}>
      <HoverDiv className="navi-footer-button"  hoverCallback={(ref,isHovered) => hoverCallback(ref,isHovered,displayText)} 
        onClick={() => {setActiveModal(ModalEnum.Language)}}>        
        <span className="icon"><LanguageSvg/></span>
        <span className="text">{displayText}</span>
      </HoverDiv>
      <div className="account-related">
        {(user)?
          <div id="account-username" className="block hlo" onClick={() => {setShowAccountMenu(!showAccountMenu)}}>
            <div className="icon"><PersonSvg/></div>
            <div className="text">{user.username}</div>
          </div>
        :null}
        {(user)?
          <div className="block">
            <div className="icon"><NotificationsSvg/></div>
          </div>
        :null}
        {(!user)?
          <NavLink className="block" to="/login">
            <div className="icon"><LoginSvg/></div>
            <div className="text">{t("navi.login")}</div>
          </NavLink>
        :null}
        {(!user)?
          <NavLink className="block" to="/create-account">
            <div className="icon"><PersonAddSvg/></div>
            <div className="text">{t("navi.createAccount")}</div>
          </NavLink>
        :null}
      </div>

      <div id="account-menu" className={"account-menu hlo"+((showAccountMenu)?"":" hide")}>
        <NavLink className="block" to="/my-account">
          <div className="icon"><PersonSvg/></div>
          <div className="text">
            <div className="display">{t("navi.myAccount")}</div>
            <div className="desc">{(user)?user.username:""}</div>
          </div>
        </NavLink>
        <div className="block" onClick={logoutUser} >
          <div className="icon"><LogoutSvg /></div>
          <div className="text">
            <div className="display">{t("navi.logout")}</div>
          </div>
        </div>
      </div>

    </div>
  );

};

export default NaviFooter;