import { createBrowserHistory } from 'history';
// import ReactGA from 'react-ga';
// import Config from '../config/config';


const history = createBrowserHistory();
// ReactGA.initialize(Config.Keys.GoogleAnalytics);

history.listen((location, action) => {
  window.scrollTo(0, 0);
  // history.pushState({}, '', href);
  // ReactGA.set({ page: location.pathname }); // Update the user's current page
  // ReactGA.pageview(location.pathname); // Record a pageview for the given page
  
  window.dispatchEvent(new Event('popstate'));
});

export default history;