import React, { useState } from "react";
import "./referrals.scss";
import Table from '../../elements/table/table';
import { request, } from "../../../utils/request";
import {toast} from 'react-toastify';

let codes = ["yt1","yt2","yt3","yt4","tw1","tw2","tw3","tw4"];

const Referrals = () => {

  const [loading,setLoading] = useState(false);

  const sideItems = [{display:"Any",conditions:[]}];
  for (let i = 0; i < codes.length; i++) {
    const code = codes[i];
    sideItems.push({
      display: code,
      conditions:[{
        action: "=",
        column:"code", 
        table:"referrals",
        value: code,
      }]
    });
  }

  const columns = [
    {table: "referrals",value: "id", index:true, },
    {table: "referrals",value: "userId", },
    {table: "referrals",value: "ip", index:true, },
    {table: "referrals",value: "code", index:true, },
    {table: "referrals",value: "createdDate", index:true, type:"date", defaultOrder:"DESC",},
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"users",rightTable:"userAuthTokens",leftColumn:"id",rightColumn:"userId",},
  ]

  const updateIps = () => {
    setLoading(true);
    request("update-ref-ips","/update-ref-ips","GET", {}, {
      then: function(res){toast.success("Update Ips Done");},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  return (
    <div className="referrals-comp">
      <div className="referrals-comp-header">
        <h3>Referrals</h3>
      </div>
      <Table 
        displayName="Referrals"
        name="ReferralsTable"
        database={"Site"}
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          // setShowUser(value);
          // setShowUserModal(true);
        }}
        buttons={[
          { 
            status: loading?"loading":"refresh",
            text:"Update Ips",
            onClick: () => {
              updateIps();
            }
          },
        ]}
        columns={columns}
        limit={250}
      />
    </div>
  );
};

export default Referrals;
