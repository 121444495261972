import React,{useState,useEffect} from "react";
import "./toggle-server.scss";
import {request,unSubRequest} from '../../../utils/request';
import {clone} from '../../../utils/clone';
import {toast} from 'react-toastify';
import Button from '../../elements/button/button';
import ReactTextareaAutosize from 'react-textarea-autosize';

const ToggleServer = () => {

  const [loading,setLoading] = useState(false);
  const [servers,setServers] = useState([]);
  const [deleteServerIds,setDeleteServerIds] = useState([]);

  useEffect(() => {
    fetchServers();
    return () => {
      unSubRequest("fetch-dawi-servers");
    }
  },[])

  const fetchServers = () => {
    let endpoint = "/dawi-servers"
    setLoading(true);
    request("fetch-dawi-servers",endpoint,"GET", {}, {
      then: function(res){
        setServers(res.data.res);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const updateServers = () => {
    let endpoint = "/dawi-servers"
    let data = {
      servers,
      deleteServerIds
    }
    console.log("data",data);
    setLoading(true);
    request("update-dawi-servers",endpoint,"POST", data, {
      then: function(res){
        setLoading(false);
        fetchServers();
      },
      catch: function(err){toast.error(err.message);setLoading(false);},
      finally: function(){}
    });
  }

  const updateValue = (index,name,value) => {
    let cloneServers = clone(servers);
    cloneServers[index].update = true;
    cloneServers[index][name] = value;
    setServers(cloneServers);
  }
 
  return (
    <div className="toggle-server-comp">
      <div className='toggle-server-header'>
        <h3>Server Toggle</h3>
        <Button parentClassName={"add-dawi-server-button"} status='add' onClick={()=>{
          let cloneServers = clone(servers);
          cloneServers.push({
            type:"Node",
            name:"",
            token:"",
            coins:"",
            create:true,
          })
          setServers(cloneServers);
        }}>Add Server</Button>
      </div>
      
      <div className="servers">
      {servers.map((v,i) => {
        return(
          <div className={"server"+((v.create)?" create":"")} key={i}>
            <div className="server-details">
              <div>
                <label>Type</label>
                <select value={v.type} onChange={(e) => {updateValue(i,"type",e.target.value)}}>  
                  <option>Node</option>
                  <option>Sign</option>
                </select>
              </div>
              <div>
                <label>Name</label>
                <input type="text" value={v.name} onChange={(e) => {updateValue(i,"name",e.target.value)}} />
              </div>
              <div>
                <label>Token</label>
                <input type="text" value={v.token} onChange={(e) => {updateValue(i,"token",e.target.value)}} />  
              </div>
              <div>
                <label>Coins</label>
                <ReactTextareaAutosize value={v.coins} type="text" onChange={(e) => {updateValue(i,"coins",e.target.value)}} />  
              </div>
            </div>
            <div className="buttons">
              <Button type="button" status="delete" onClick={() => {
                let cloneServers = clone(servers);
                let cloneDeleteServerIds = clone(deleteServerIds)
                cloneServers.splice(i, 1);
                if(v.id){
                  cloneDeleteServerIds.push(v.id);
                }
                setServers(cloneServers);
                setDeleteServerIds(cloneDeleteServerIds);
              }}>
                Delete
              </Button>
            </div>
           
          </div>
        )
      })}
      </div>
     
      <div className="buttons">
        <Button status={loading?"loading":"save"} type="button" onClick={() => {updateServers()}}>
          Save Servers
        </Button>
      </div>
    </div>
  );
};

export default ToggleServer;
