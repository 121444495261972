

const Settings = {
  TopNavi: false,
  SideNavi: true,
  StickyNavi: true,
  Footer: false,
  LoadingCoinsCount: 10, 
  LoadingCoinsArray: [],
};

let loadingCoinsArray = []
for (let i = 0; i < Settings.LoadingCoinsCount; i++) { loadingCoinsArray.push(i) }
Settings.LoadingCoinsArray = loadingCoinsArray;

export default Settings;
