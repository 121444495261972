import React from 'react';

import * as DateUtil from '../../../../utils/date';
import * as StringUtil from '../../../../utils/string';
import Button from '../../../elements/button/button';

import {AccountStatusIdEnum} from '../../../../enums/account-status';
import {toast} from 'react-toastify';

const Profile = ({viewUser,load}) => {

  return (
    <div className="profile">
      {(!viewUser)?null:
        Object.keys(viewUser).map((key, i) => {
          let value = viewUser[key];

          if(key === "usersStatus"){
            value = AccountStatusIdEnum[value];
          }else if(key === "usersCreatedDate" || key === "usersLastActive"){ 
            let timeSince = DateUtil.dateTimeSince(value,1,true);
            let prettyTime = DateUtil.prettifyDateWithTime(value);
            let epochTime = new Date(value).getTime();
            value = timeSince+" - "+prettyTime+" - "+epochTime
          }

          return (
            <div className='item' key={i}>
              <div>{StringUtil.prettifyCamelCase(key)}</div>
              <input type="text" disabled value={value}/>
            </div>
          )
        })
      }
      <Button onClick={() => {toast.info("I need code")}}>Disable Account</Button>
    </div>
  );

};

export default Profile;