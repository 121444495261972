export const numberWithCommas = (number) => {
  if(number === undefined || number === null || isNaN(number)){
    return number;
  }
  let foo = preventScientificNotation(number).toString();
  let bar = "";
  let split = foo.split(".");
  if(split.length > 1){
    foo = split[0];
    bar = "."+split[1];
  }
  let baz = foo.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return baz+bar;
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const formatCurrency = (cents) => {
  var res = cents / 100;
  res = res.toLocaleString("en-US", {style:"currency", currency:"USD"});
  return res;
}

export const findPercentageIncreaseOrDecrease = (newValue, originalValue) => {
  let increase = newValue - originalValue;
  let percentageIncrease = increase/originalValue*100
  return percentageIncrease;
}

export const satsToVal = (num) => {
  if(isNaN(parseInt(num))){ return num; }
  let round = Math.round(parseInt(num));
  let foo = parseFloat(round) / 100000000;
  let bar = parseFloat(foo).toFixed(8);
  let baz = parseFloat(bar);
  return baz;
}

export const centsToVal = (num) => {
  if(isNaN(parseInt(num))){
    return num;
  }
  let round = Math.round(parseInt(num));
  let foo = parseFloat(round) / 100;
  let bar = parseFloat(foo).toFixed(2);
  let baz = parseFloat(bar);
  return baz;
}

export const valToSats = (num) => {
  if(isNaN(parseInt(num))){return 0;}
  return Math.round(parseFloat(num) * 100000000)
}

export const valToCents = (num) => {
  if(isNaN(parseInt(num))){return 0;}
  return Math.round(parseFloat(num) * 100)
}

export const countDecimals = (value) => {
  if(Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0; 
}

export const valFloatPointFix = (val) => {
  let foo = Math.round(parseFloat(val) * 100000000)/100000000;
  let bar = parseFloat(foo).toFixed(8);
  let baz = parseFloat(bar);
  return baz
}

export const formatDollar = (num) => {
  if(!num) return "0";

  let p = parseFloat(num).toFixed(2).split(".");
  return p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
}

export const preventScientificNotation = (num) => {
  return Number(num).toFixed(8).replace(/\.?0+$/,"")
}