import React from 'react';
import './user-roles.scss';

const UserRoles = () => {

  return (
    <div className="user-roles">
      <h1>user roles</h1>
    </div>
  );

};

export default UserRoles;