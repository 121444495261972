import React, {useState, useEffect } from 'react';
import './totp-modal.scss';
import Modal from '../../common/modal/modal';
import Loading from '../../common/loading/loading';
import Button from '../../elements/button/button';
import {request,unSubRequest} from '../../../utils/request';
import {toast} from 'react-toastify';
import QRCode from 'react-qr-code';
import useUserState from '../../../store/user';
import { clone } from '../../../utils/clone';


const TotpModal = ({show,handleClose}) => {

  const {user,setUser} = useUserState();

  const [loading,setLoading] = useState(false);
  const [loadingLink,setLoadingLink] = useState(false);
  const [secret,setSecret] = useState("");
  const [otpAuthUrl,setOtpAuthUrl] = useState("");
  const [inputVal,setInputVal] = useState("");

  useEffect(() => {
    generateTotpRequest();
    return () => {
      unSubRequest("user-totp-generate");
      unSubRequest("user-totp-link");
    }
  },[]); 

  const generateTotpRequest = () => {
    setLoading(true);
    request("user-totp-generate","/user-totp-generate","POST", {}, {
      then: function(res){
        setSecret(res.data.res.secret);
        setOtpAuthUrl(res.data.res.otpAuthUrl);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoading(false);}
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    linkTotpRequest();
  }

  const linkTotpRequest = () => {

    let data = {
      secret: secret,
      token: inputVal,
    }

    setLoadingLink(true);
    request("user-totp-link","/user-totp-link","POST", data, {
      then: function(res){
        toast.success("TOTP enabled");
        let cloneUser = clone(user);
        cloneUser.totpSet = true;
        setUser(cloneUser);
        handleClose();
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){setLoadingLink(false);}
    });
  }

  return (
    <Modal className="totp-modal" show={show} handleClose={handleClose}>
      <div className="totp-modal-div">
        <h3>2FA TOTP Set Up</h3>
        <Loading show={loading} />
        <div className="block">
          <span>Download a TOTP based authentication app. Here are some good options.</span>
          <span>Tofu, Microsoft Authenticator, Google Authenticator, Authy, Duo Mobile, LastPass Authenticator</span>
        </div>
        <div className="block">
          <span>Save this code, you'll need it if you lose your device. </span>
          <span>Code: {(secret)?secret:""}</span>
        </div>
        <div className="block">
          <span>Scan this QR Code</span>
          <QRCode className="qr-code" size={160} value={(otpAuthUrl)?otpAuthUrl:""} />
          <span>Or enter the above code manually</span>
        </div>
        <div className="block">
          <form onSubmit={handleSubmit}>
            <label>Enter the 6 digit TOTP code here. </label>
            <input type="text" placeholder="Code" value={inputVal} onChange={(e) => {setInputVal(e.target.value)}}/>
            <Button type="submit" status={(loadingLink)?"loading":""} />
          </form>
        </div>
      </div>
    </Modal>
  );

};

export default TotpModal;