export const myAccount = {
  myAccount: {
    en: "My Account",
    ja: "マイアカウント",
    et: "Minu konto",
  },
  profile: {
    en: "Profile",
    ja: "プロフィール",
    enet: "Profiil",
  },
  username: {
    en: 'Username',
    ja: 'ユーザー名',
    enet: 'Kasutajanimi',
  },
  email: {
    en: 'Email',
    ja: 'メール',
    et: 'E-mail',
  },
  password: {
    en: 'Password',
    ja: 'パスワード',
    et: 'Parool',
  },
  changeUsername: {
    en: "Change your Username",
    ja: "ユーザー名変更",
    et: "Muuda oma kasutajanime",
  },
  changeEmail: {
    en: "Change your Email",
    ja: "メール変更",
    et: "Muuda oma e-maili",
  },
  changePassword: {
    en: "Set Password",
    ja: "パスワードセット",
    et: "Määra parool"
  },
  newUsername: {
    en: "New Username",
    ja: "新ユーザー名",
    et: "Uus kasutajanimi",
  },
  newEmail: {
    en: "New Email",
    ja: "新メール",
    et: "Uus e-mail",
  },
  newPassword: {
    en: "New Password",
    ja: "新パスワード",
    et: "Uus parool",
  },
  setNewUsername: {
    en: "Set New Username",
    ja: "新ユーザー名設定する",
    et: "Määra uus kasutajanimi",
  },
  setNewEmail: {
    en: "Set New Email",
    ja: "新メール設定する",
    et: "Määra uus e-mail",
  },
  setNewPassword: {
    en: "Set New Password",
    ja: "新パスワード設定する",
    et: "Määra uus parool",
  },
  currentPassword:{
    en: "Current Password",
    ja: "現在のパスワード",
    et: "Praegune parool",
  },
  forgotPassword: {
    en: "Forgot?",
    ja: "忘れた？",
    et: "Unustasid parooli?",
  },
  troubleLoggingIn: {
    en: "Trouble Logging in?",
    ja: "ログインできませんか?",
    et: "Kas teil on probleeme sisselogimisega?",
  },
  successUsername:{
    en: "Username Changed",
    ja: "ユーザー名変更しました。",
    et: "Kasutajanimi muudetud",
  },
  successEmail:{
    en: "An email has been sent to {{email}}, please check it and proceed from there. ",
    ja: "{{email}}にメール送れいました。ご確認ください。",
    et: "E-mail on saadetud aadressile {{email}}, palun kontrolli ja jätka sealt",
  },
  successPassword:{
    en: "Password Changed",
    ja: "パスワード変更しました。",
    et: "Prool muudetud",
  },
  linkSnsAccountsHeader:{
    en: "Link SNS Accounts",
    ja: "SNSアカウント追加",
    et: "Lingi SNS kontod",
  },
  linkSnsAccountsText:{
    en: "Link your social media accounts to easily log in",
    ja: "SNSアカウントリンクすればログインがやりやすくなれます。",
    et: "Lingi oma sotsiaalmeedia kontod, et lihtsasti sisse logida",
  },
  dangerZone: {
    en: "Danger Zone",
    ja: "危険ゾーン",
    et: "Ohutsoon",
  },
  deleteAccount: {
    en: "Delete Account",
    ja: "アカウント解除",
    et: "Kustuta konto",
  },
  deleteAccountWarning: {
    en: "This action is unreversible. Please be certain. ",
    ja: "このアクションは元に戻すことができません。 確認してください。",
    et: "See tegevus on lõplik, kas oled kindel?",
  },
  deleteAccountText:{
    en: "To proceed with deleting this account. Please type in your username, email and password for the account. ",
    ja: "このアカウントの削除を続行するために、アカウントのユーザー名、メールアドレス、パスワードを入力してください。",
    et: "Konto kustutamiseks sisesta palun oma kasutajanimi, email ja parool."
  },
  deleteAccountForever: {
    en: "Delete Account Forever",
    ja: "永久にアカウント削除",
    et: "Kustuta konto lõplikult",
  },
  accountType: {
    en: "Account Type",
    ja: "アカウントタイプ",
    et: "Konto tüüp",
  },
  upgrade:{
    en: "Upgrade",
    ja: "アップグレードする",
    et: "Uuenda",
  },
}

