import React from 'react';
import './version.scss';

import packageJson from '../../../../package.json';
import {Environment,} from '../../../config/env';
import Config from '../../../config/config';


const Version = () => {

  console.log("Config",Config);

  return (
    <div className="version-comp">
      <h1>Version</h1>
      <div>App: {Config.Common.AppName}</div>
      <div>App Version: {packageJson.version}</div>
      <div>React Version: {React.version}</div>
      <div>Environment: {Environment}</div>
    </div>
  );

};

export default Version;