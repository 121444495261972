import React, {useState, useEffect, useRef} from 'react';
import './carousel.scss';

// Wrapped children components must be placed inside of <div> elements
const Carousel = ({slideTo, children}) => {

  const [carouselStyle, setCarouselStyle] = useState({});
  const activeRef = useRef(null);
  const index = slideTo - 1

  const newChildren = [];
  children.map((d,i) => {
    let addClass = (d.props.className !== undefined) ? d.props.className: ""
    const newProps = { 
      key: i 
    };

    if(i === index){
      addClass += " active"
      newProps.ref = activeRef;
    }

    newProps.className = addClass.trim();

    const newChild = React.cloneElement(d, newProps);

    newChildren.push(newChild);
    return false
  });

  let carouselContainerStyle = {
    left: (((slideTo * 100) - 100) * -1) + "%",
    width: (newChildren.length * 100)+ "%",
  }

  useEffect(() => {
    const interval = setInterval(function(){
      if(activeRef != null){
        // console.log("activeRef",activeRef);
        if(
          carouselStyle.height === undefined || 
          (activeRef !== null && activeRef.current !== null && activeRef.current.clientHeight !== null && carouselStyle.height !== activeRef.current.clientHeight)){
          setCarouselStyle({
            height: activeRef.current.clientHeight,
          });
        }
      }      
    },50);
    return () => {
      if(interval){ clearInterval(interval) }
    }
  },[carouselStyle]);

  useEffect(() => {
    if(activeRef.current !== null){
      setCarouselStyle({
        height: activeRef.current.clientHeight,
      });
    }
  },[slideTo]);

  return (
    <div className="carousel" style={carouselStyle}>
      <div style={carouselContainerStyle} className="carousel-container">
        {newChildren}
      </div>
    </div>
  );
};

export default Carousel;