import { useGlobal } from 'reactn';

const useModalState = () => {
  
  const [activeModal, setActiveModal] = useGlobal('modal'); 

  return {activeModal, setActiveModal};
}

export default useModalState;

export const ModalEnum = {
  Language: "language",
}