import React from 'react';
import './dashboard.scss';

const Dashboard = () => {

  return (
    <div className="dashboard">
      <h3>Dashboard</h3>

    </div>
  );

};

export default Dashboard;