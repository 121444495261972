import React from 'react';

const Activity = ({viewUser,load}) => {

  return (
    <div className="activity-comp">
      activity-comp
    </div>
  );

};

export default Activity;