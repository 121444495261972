export const footer = {
  about: {
    en: "About",
    ja: "私たちについて",
  },
  services: {
    en: "Services",
    ja: "サービス",
  },
  support: {
    en: "Support",
    ja: "サポート",
  },
  community: {
    en: "Community",
    ja: "コミュニティ",
  },
  aboutUs: {
    en: "About Us",
    ja: "私たちについて",
  },
  careers: {
    en: "Careers",
    ja: "キャリア",
  },
  termsOfUse: {
    en: "Terms of Use",
    ja: "利用規約",
  },
  privacyPolicy: {
    en: "Privacy Policy",
    ja: "プライバシーポリシー",
  },
  library: {
    en: "Library",
    ja: "図書館",
  },
  community: {
    en: "Community",
    ja: "コミュニティ",
  },
  becomeATeacher: {
    en: "Become a Teacher",
    ja: "教師になる",
  },
  helpCenter: {
    en: "Help Center",
    ja: "ヘルプセンター",
  },
  help: {
    en: "Help",
    ja: "ヘルプ",
  },
  submitFeedback: {
    en: "Submit Feedback",
    ja: "フィードバックを提出",
  },
  reportABug: {
    en: "Report a Bug",
    ja: "バグを報告",
  },
  siteHealthStatus: {
    en: "Site Health Status",
    ja: "サイトの健康状態",
  },
};
