import React from 'react';
import './pagination.scss';

const Pagination = ({page,pages,setPage,}) => {
  let before = 2;
  let after = 2;
  let items = [];

  pages = (pages)?pages:0

  let beforeItem = page - before;
  if (page + after > pages) {
    beforeItem += pages - (page + after);
  }
  while (beforeItem < page) {
    if (beforeItem <= 0) {
      after++;
      beforeItem++;
      continue;
    }
    items.push(beforeItem);
    beforeItem++;
  }

  items.push(page);

  let afterItem = 1;
  while (afterItem <= after && afterItem + page <= pages) {
    items.push(page + afterItem);
    afterItem++;
  }

  // First
  if (items.indexOf(1) === -1) {
    items.unshift(1);
  }
  // Last
  if (items.indexOf(pages) === -1) {
    items.push(pages);
  }

  const lastVal = items[items.length - 1];
  const secondLastVal = (items.length >= 2)?items[items.length - 2]:null;

  return(
    <div className="pagination hlo">
      <ul>
        {(pages === 0)?null:
          items.map((v,i) => {
            let itemClass = "";
            if(v === page) itemClass += "active";
            if(items.length >= 2 && v === 1 && items[1] !== 2) itemClass += "first";
            if(secondLastVal && v === lastVal && secondLastVal !== (lastVal - 1)) itemClass += "last";
            return(
              <li className={itemClass} key={i}>
                <div onClick={() => {setPage(v)}}>{v}</div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
};

export default Pagination;
