import React,{useState} from 'react';
import './entry.scss';

import CenterDiv from '../../common/center-div/center-div';
import CarouselHeader from '../../common/carousel/carousel-header';
import Carousel from '../../common/carousel/carousel';
import SignIn from './sign/sign-in';
import SignUp from './sign/sign-up';
import SnsButton from '../../elements/sns-button/sns-button';
import Logo from '../../common/logo/logo';

const Entry = ({option,loginSuccessCallback,createAccountSuccessCallback}) => {
  let slideInit = 1;
  if(option !== null && option === "create-account"){;
    slideInit = 2;
  }

  const snsOptions = ["google"];

  const [slide,setSlide] = useState(slideInit);
  const [check,setCheck] = useState(false);

  const checkUpdateFunc = (check) => { setCheck(check); }

  return (
    <CenterDiv>
      <div className="entry">
        <div className="entry-header">
          <Logo options="large"/>
        </div>
        <CarouselHeader className="entry-options hlo" slideTo={slide}>
          <div onClick={() => {setSlide(1)}}>Sign In</div>
          <div onClick={() => {setSlide(2)}}>Create Account</div>
        </CarouselHeader>
        <Carousel slideTo={slide}>
          <div className="sign-in-slide"><SignIn successCallback={loginSuccessCallback}/></div>
          <div className="sign-up-slide"><SignUp successCallback={createAccountSuccessCallback} checkUpdateFunc={checkUpdateFunc}/></div>
        </Carousel>

        {(snsOptions.length <= 0)?null:
          <div className="entry-sns">
            <div className="line-div">
              <span className="line"></span>
              <span className="text">or</span>
              <span className="line"></span>
            </div>
            {snsOptions.map((d,i) => {return(<SnsButton key={i} snsType={d} checkNeeded={slide === 2 && !check} />)})}
          </div>
        }
        
      </div>
    </CenterDiv>
  );

};

export default Entry;