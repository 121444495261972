import React, {useState, } from 'react';
import './account-security.scss';
import SwitchToggle from '../../elements/switch-toggle/switch-toggle';
import useUserState from '../../../store/user';
import TotpModal from './totp-modal';
import TotpDisableModal from './totp-disable-modal';

const AccountSecurity = () => {

  const {user} = useUserState();
  const [showTotpModal,setShowTotpModal] = useState(false);
  const [showTotpDisableModal,setShowTotpDisableModal] = useState(false);


  let totpSet = (user && user.totpSet)?true:false;

  return (
    <div id="account-security" className="account-security">
      <h3>Account Security</h3>

      <div className="block">
        <div className="text">
          <span className="display">2 Factor Authentication</span>
          <span className="sub-display">Use a TOTP Authenticator to protect your account.</span>
        </div>
        <SwitchToggle isOn={totpSet} onClickFunc={() => {
            setShowTotpDisableModal(totpSet);
            setShowTotpModal(!totpSet);
          }}/>
      </div>

      <TotpDisableModal handleClose={() => {setShowTotpDisableModal(false)}} show={showTotpDisableModal}/>
      <TotpModal handleClose={() => {setShowTotpModal(false)}} show={showTotpModal} />
    </div>
  );

};

export default AccountSecurity;